var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "examinationTimeoutRecord-page" }, [
    _c(
      "div",
      { staticClass: "form-box" },
      [
        _c(
          "el-form",
          {
            attrs: {
              model: _vm.form,
              inline: "",
              "label-position": "left",
              "label-width": "85px",
            },
          },
          [
            _c(
              "el-form-item",
              { staticClass: "time-box", attrs: { label: "例检有效期：" } },
              [
                _c("el-input", {
                  on: {
                    input: () =>
                      (_vm.form.lastHour = _vm.form.lastHour.replace(
                        /^(0+)|[^\d]+/g,
                        ""
                      )),
                  },
                  model: {
                    value: _vm.form.lastHour,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "lastHour",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.lastHour",
                  },
                }),
                _c("span", [_vm._v("小时")]),
              ],
              1
            ),
            _c(
              "el-form-item",
              { staticClass: "company-box", attrs: { label: "所属企业：" } },
              [
                _c("companySelect", {
                  attrs: { clearable: "", "company-tree": _vm.companyList },
                  on: { getValue: _vm.getAddGroupId, getName: _vm.getName },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _vm.listFind("查询")
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "small",
                          loading: _vm.buttonLoading,
                        },
                        on: { click: _vm.queryFun },
                      },
                      [_vm._v("查询")]
                    )
                  : _vm._e(),
                _vm.listFind("导出")
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          loading: _vm.buttonLoading,
                          type: "primary",
                          size: "small",
                        },
                        on: { click: _vm.exportFun },
                      },
                      [_vm._v("导出")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "table-box" },
      [
        _c("Table", {
          attrs: { "table-data": _vm.tableData, "title-name": _vm.titleName },
        }),
        _c("el-pagination", {
          attrs: {
            background: "",
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
            "page-size": _vm.form.pageSize,
            "current-page": _vm.form.currentPage,
          },
          on: {
            "size-change": _vm.sizeChangeFun,
            "current-change": _vm.currentChangeFun,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }