<template>
  <!-- 例检超时记录 -->
  <div class="examinationTimeoutRecord-page">
    <div class="form-box">
      <el-form :model="form" inline label-position="left" label-width="85px">
        <el-form-item label="例检有效期：" class="time-box">
          <el-input
            v-model.trim="form.lastHour"
            @input="
              () => (form.lastHour = form.lastHour.replace(/^(0+)|[^\d]+/g, ''))
            "
          ></el-input>
          <span>小时</span>
        </el-form-item>
        <el-form-item label="所属企业：" class="company-box">
          <companySelect
            clearable
            :company-tree="companyList"
            @getValue="getAddGroupId"
            @getName="getName"
          ></companySelect>
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="listFind('查询')"
            type="primary"
            size="small"
            :loading="buttonLoading"
            @click="queryFun"
            >查询</el-button
          >
          <el-button
            v-if="listFind('导出')"
            :loading="buttonLoading"
            type="primary"
            size="small"
            @click="exportFun"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="table-box">
      <Table :table-data="tableData" :title-name="titleName"> </Table>
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="sizeChangeFun"
        @current-change="currentChangeFun"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getCompanyTree,
  queryRoutineInspectionRecordListAPI,
  exportRoutineInspectionTimeOutRecordAPI,
} from "@/api/lib/api.js";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      buttonLoading: false,
      total: 0,
      exportParams: {},
      form: {
        lastHour: "24",
        companyId: "", //公司ID
        companyName: "", //公司名称
        pageSize: 10,
        currentPage: 1,
      },
      companyList: [],
      tableData: [],
      titleName: [
        {
          title: "车牌号",
          props: "licensePlateNumber",
        },
        {
          title: "车属公司",
          props: "companyName",
        },
        {
          title: "上次例检时间",
          props: "inspectionTime",
        },
      ],
    };
  },
  filters: {},
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getCompanyList();
    this.getQueryRoutineInspectionRecordList();
  },
  methods: {
    /**
     * @description 查询
     */
    queryFun() {
      if (!this.form.lastHour) {
        this.form.lastHour = "24";
      }
      this.exportParams = JSON.parse(JSON.stringify(this.form));
      this.form.currentPage = 1;
      this.getQueryRoutineInspectionRecordList();
    },
    // 当前页码改变
    currentChangeFun(page) {
      this.form.currentPage = page;
      this.getQueryRoutineInspectionRecordList();
    },
    // 当前条数改变
    sizeChangeFun(size) {
      this.form.pageSize = size;
      this.getQueryRoutineInspectionRecordList();
    },
    /**
     * @description 导出
     */
    exportFun() {
      delete this.exportParams.pageSize;
      delete this.exportParams.currentPage;
      delete this.exportParams.companyName;
      exportRoutineInspectionTimeOutRecordAPI(this.exportParams).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "例检超时记录 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");
        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击=
        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    /**
     * @description 获取例检超时记录
     */
    getQueryRoutineInspectionRecordList() {
      queryRoutineInspectionRecordListAPI(this.form).then((res) => {
        if (res.code === "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    /**
     * @description 获取企业树
     */
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    // 获取企业ID
    getAddGroupId(id) {
      this.form.companyId = id == null ? "" : id;
    },
    // 获取企业名称
    getName(name) {
      this.form.companyName = name;
    },
  },
};
</script>

<style lang="scss" scoped>
.examinationTimeoutRecord-page {
  width: 100%;
  .form-box {
    background-color: #ffffff;
    padding: 20px 0 0 20px;
    .company-box {
      margin-right: 20px;
    }
    .time-box {
      .el-input {
        width: 50%;
      }
    }
  }
  .table-box {
    margin-top: 20px;
    background-color: #ffffff;
    padding: 20px;
  }
}
</style>
